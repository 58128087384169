import { APP_THEME } from '.';

export const AD_PREFIX = 'hedux';

export const AD_BACKGROUNDS_BY_THEMES: Record<APP_THEME, string> = {
  [APP_THEME.Brand]: 'var(--colorMiddleBlueBackground)',
  [APP_THEME.Stars]: 'var(--colorCarbonBackground)',
  [APP_THEME.Sign]:  'var(--colorAlternativeBlueBackground)',
  [APP_THEME.White]: 'var(--colorAlternativeBlueBackground)',
};

export const PUB_SCOPE = '.rambler.ru';
