export enum SIGN {
  aries = 'aries',
  taurus = 'taurus',
  gemini = 'gemini',
  cancer = 'cancer',
  leo = 'leo',
  virgo = 'virgo',
  libra = 'libra',
  scorpio = 'scorpio',
  sagittarius = 'sagittarius',
  capricorn = 'capricorn',
  aquarius = 'aquarius',
  pisces = 'pisces',
}

export type SignType = 'aries'
  | 'taurus'
  | 'gemini'
  | 'cancer'
  | 'leo'
  | 'virgo'
  | 'libra'
  | 'scorpio'
  | 'sagittarius'
  | 'capricorn'
  | 'aquarius'
  | 'pisces';

export enum SIGN_CHINESE {
  rat = 'rat',
  ox = 'ox',
  tiger = 'tiger',
  rabbit = 'rabbit',
  dragon = 'dragon',
  snake = 'snake',
  horse = 'horse',
  sheep = 'sheep',
  monkey = 'monkey',
  rooster = 'rooster',
  dog = 'dog',
  pig = 'pig',
}

export type SignTypeChinese = 'rat'
  | 'ox'
  | 'tiger'
  | 'rabbit'
  | 'dragon'
  | 'snake'
  | 'horse'
  | 'sheep'
  | 'monkey'
  | 'rooster'
  | 'dog'
  | 'pig';
