export const PORT = process.env.APP_PORT || 3000;

export const CACHE = false;

export const API_CACHE_TIME = 60;
export const API_URL = 'https://stage.horos.rambler.ru/api/front/';
export const API_TIMEOUT = 3000;
export const API_TIMEOUT_CLIENT = 10000;

export const API_SUBSCRIPTION = 'https://subscriptions-stage.rambler.ru';

export const API_GEO_LOCATION = 'https://www.rambler.ru/location/current';

export const REDIS_API_CACHE_TIMEOUT = 100;

export const { CAPTCHA_KEY } = process.env;

export const METRICS = true;

const logObj = {
  port:      514,
  type:      'BSD',
  facility:  'local0',
  localhost: process.env.LOG_INDEX!,
};
export const SYSLOG = {
  address: process.env.LOG_NODE!,
  ...logObj,
};
export const LOGLEVEL = '';
export const LOGGING = false;

export const SENTRY_DSN = 'https://4f714e0240310a72fac5a34261baf9c1@sentry-saas.rambler-co.ru/95';
export const SENTRY_SAMPLE_RATE_SERVER = 1;
export const SENTRY_SAMPLE_RATE_BROWSER = 1;

export const METRICS_SAMPLE_RATE = 1;

export const HTTPS_PROXY = '';
export const HD_REJECT_UNAUTHORIZED = !(process.env.HD_REJECT_UNAUTHORIZED && process.env.HD_REJECT_UNAUTHORIZED === 'false');

export const REDIS: Config['REDIS'] = {
  CLUSTER: [
    {
      port: 7001,
      host: '127.0.0.1',
    },
    {
      port: 7002,
      host: '127.0.0.1',
    },
    {
      port: 7003,
      host: '127.0.0.1',
    },
  ],
  OPTIONS: {
    natMap: {
      '10.0.0.11:7001': { host: '127.0.0.1', port: 7001 },
      '10.0.0.12:7002': { host: '127.0.0.1', port: 7002 },
      '10.0.0.13:7003': { host: '127.0.0.1', port: 7003 },
      '10.0.0.14:7004': { host: '127.0.0.1', port: 7004 },
      '10.0.0.15:7005': { host: '127.0.0.1', port: 7005 },
      '10.0.0.16:7006': { host: '127.0.0.1', port: 7006 },
    },
  },
};

export const NEWS_LETTER_CRM_KEYS = {
  BIORHYTHMS: 'daily_biorhythms_stage',
  TAROT:      'weekly_tarot_stage',
  DAILY_SING: 'daily_sign_horoscopes_stage',
  ARTICLES:   'media_article_stage',
};

export const SPLITS_JSON = `${API_URL}v3/experiments/`;
