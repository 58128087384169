export enum CLUSTER_TYPE {
  news = 0,
  photo = 1,
  video = 2,
  article = 3,
  test = 4,
}

/**
 * Статусы кластера
 * @param toRemove - Скрыт. 404. Кластер скрыт, так как ссылка на оригинал (источник)
 * @param new - Скрыт. 404. Только созданные
 * @param baked - Активен
 * @param unknown2 - Скрыт. Древний статус
 * @param unknown3 - Скрыт. Древний статус
 * @param trash - Скрыт. Древний статус
 * @param hidden - Скрыт в админке
 * @param censored - Скрыт. Цензура
 * @param uncensored - Активен
 * @param noFulltext - Скрыт. Неполнотекстовые кластера. (!) Отказываемся
 */
export enum CLUSTER_STATUS {
  toRemove = -1, // 404
  new = 0, // 404
  baked = 1, // 200
  unknown2 = 2, // 404
  unknown3 = 3, // 404
  trash = 4, // 404
  hidden = 5, // 410
  censored = 6, // 410
  uncensored = 7, // 200
  noFulltext = 8, // 410
}

type ClusterNameType = 'news' | 'photo' | 'video' | 'article' | 'test';

export const CLUSTER_NAME_TYPES: Record<CLUSTER_TYPE, ClusterNameType> = {
  [CLUSTER_TYPE.news]:    'news',
  [CLUSTER_TYPE.photo]:   'photo',
  [CLUSTER_TYPE.video]:   'video',
  [CLUSTER_TYPE.article]: 'article',
  [CLUSTER_TYPE.test]:    'test',
};
