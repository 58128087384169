export enum MoonPhases {
  /* eslint-disable camelcase */
  new_moon = 1,
  waxing_moon = 2,
  full_moon = 16,
  waning_moon = 17,
  /* eslint-enable camelcase */
}

export const MOON_TYPES: Record<MoonPhases, string> = {
  [MoonPhases.new_moon]:    'новолуние',
  [MoonPhases.waxing_moon]: 'растущая',
  [MoonPhases.full_moon]:   'полнолуние',
  [MoonPhases.waning_moon]: 'убывающая',
};

export const getMoonType = (day: number) => {
  let moonType = '';

  if (day === 1 || day === 30) {
    moonType = MOON_TYPES[MoonPhases.new_moon];
  } else if (day >= 2 && day <= 15) {
    moonType = MOON_TYPES[MoonPhases.waxing_moon];
  } else if (day === 16) {
    moonType = MOON_TYPES[MoonPhases.full_moon];
  } else if (day >= 17 && day <= 29) {
    moonType = MOON_TYPES[MoonPhases.waning_moon];
  }

  return moonType;
};
