import testLogo from './i/test/logo.png';
import testScreenshot from './i/test/screenshot.png';
import testScreenshotMobile from './i/test/screenshotMobile.png';

const testId = 45126538;

// eslint-disable-next-line import/prefer-default-export
export const SCROLL2SITE_INFO: (
  runProfile: RuntimeType['config']['runProfile']
) => Partial<{
  [id: number]: {
    logo: string
    screenshot: string
    screenshotMobile: string
    url: string
  }
}> = runProfile => {
  if (runProfile === 'local') {
    return {
      [testId]: {
        logo:             testLogo,
        screenshot:       testScreenshot,
        screenshotMobile: testScreenshotMobile,
        url:              '//rambler.ru',
      },
    };
  }

  return {};
};
