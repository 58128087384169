import { APP_VERSION } from '.';

export const GA_IDS = {
  [APP_VERSION.MOBILE]:  'UA-8038853-5',
  [APP_VERSION.DESKTOP]: 'UA-8038853-5',
};

export const TNS_IDS = {
  [APP_VERSION.MOBILE]:  'rambler_horo',
  [APP_VERSION.DESKTOP]: 'rambler_horo', // как у мобильной версии
  sberSeller:            'rambler_cid1100104-posid2155079',
  rambler:               'rambler_cid1100108-posid2155111',
};

export enum YANDEX_METRICS {
  COMMON = 29581275,
  PORTAL = 26649402,
  DESKTOP_UNIQUE = 26557758,
  MOBILE_PORTAL = 27440552,
  MOBILE_UNIQUE = 30260582
}

export const RCM_ID = 'RCM-122C';

export const GTM_ID_RAMBLER = 'GTM-KJBSQR';
export const GTM_ID_MARKETING = 'GTM-5JF3NFJ'; // Лид магнит. Всплывашки, рассылки, подписки, быстрая реклама аля селф промо и т.д.

// один на все версии
export const TOP100_ID = 213403;
// для отслеживания суперпользователей по всему холдингу
export const TOP100_COMMON_ID = 7726560;
// для отслеживания суперюзеров внутри портала Рамблер
export const TOP100_RAMBLER_ID = 7728281;

export const GEMIUS_ID = 'nSzgvPuSG0jfABCp5jRWjnZHXmDBvAdjV.RMPABTRG3.57'; // Один на все версии
