import { setRswitchMobile } from 'utils/rswitcher';

export type SubMenuItem = {
  title: string
  key?: string
  href: string
  isExternal?: boolean
  'data-blocks': string
  onClick?: () => void
  code?: string | RegExp
};

export const desktopSubMenu = (): SubMenuItem[] => [
  {
    title:         'Мобильная версия',
    href:          '/',
    onClick:       setRswitchMobile,
    key:           'mobile',
    'data-blocks': 'mobile',
  },
  {
    title:         'Реклама',
    href:          'https://reklama.rambler.ru/sites/horoscopes/?about=1',
    isExternal:    true,
    key:           'adv',
    'data-blocks': 'adv',
  },
  {
    title:         'Редакционная политика',
    href:          'https://help.rambler.ru/news/redakcionnaya_politika/1853/',
    isExternal:    true,
    key:           'editorial',
    'data-blocks': 'editorial',
  },
  {
    title:         'О проекте',
    href:          '/editors/',
    isExternal:    false,
    key:           'about',
    'data-blocks': 'about_project',
    code:          /editors-main|editors-author-.+/g,
  },
  {
    title:         'Помощь',
    href:          'https://help.rambler.ru/feedback/horoscopes/',
    isExternal:    true,
    key:           'help',
    'data-blocks': 'help',
  },
];
