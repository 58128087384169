export const API: APIsUrls = {
  horoscope: {
    main:          'main/',
    general:       'general/',
    woman:         'woman/',
    man:           'man/',
    love:          'love/',
    finance:       'finance/',
    sex:           'sex/',
    description:   'description/',
    compatibility: 'compatibility/',
    birthstone:    'birthstone/',
  },
  articles: {
    list:   'main/',
    topic:  'topic/',
    detail: 'detail/',
    latest: 'latest/',
  },
  dreams: {
    main:    'main/',
    word:    '',
    periods: '',
    letters: 'letters/',
  },
  moon: {
    calendar: 'calendar/',
    day:      'day/',
    full:     'full-moon/',
    new:      'new-moon/',
  },
  sun: {
    calendar: 'calendar/',
  },
  account: {
    users: 'users/',
  },
  card: {
    likes: 'likes/',
  },
  compatibility: {
    form: 'compatibility/form/',
  },
  search: {
    dreams: 'dreams/data/words/',
    names:  'names/data/words/',
    omens:  'omens/data/words/',
    taro:   '',
  },
  fortune: {
    main:               '',
    coffee:             'coffee/',
    tarot:              'celtic-cross-full/',
    runes:              'runes-full/',
    snippet:            'fortune/coffee/snippet/',
    taromain:           'tarot/main/',
    tarocards:          'tarot/cards/',
    tarocategory:       'tarot/suits/',
    taroonecard:        'tarot/cards/',
    taroonline:         'tarot/online/',
    tarofuture:         'tarot/future/',
    tarolove:           'tarot/love/',
    taroshort:          'tarot/yes_no/',
    tarobirthdate:      'tarot/birthdate/',
    taroguide:          'tarot/guide/',
    taroforecast:       'tarot/forecast/all/',
    taroforecastbysign: 'tarot/forecast/',
  },
  feedbacks: {
    main: 'feedbacks/',
  },
  editors: {
    main:   '',
    author: '',
  },
  garden: {
    main:   'calendar/main/',
    period: 'calendar/',
  },
  hair: {
    main:   'calendar/main/',
    period: 'calendar/',
    date:   'calendar/',
  },
  chinese: {
    main:          '',
    description:   'description/',
    compatibility: 'compatibility/',
    forecast:      'forecast/',
    date:          'chinese/date/',
  },
  dashboard: {
    main: '',
  },
  special: {
    main: '',
  },
  names: {
    main:       '',
    name:       'name/',
    letters:    '',
    date:       '',
    calendar:   'calendar/main/',
    month:      'calendar/',
    category:   'category/',
    patronymic: 'patronymic/',
  },
  omens: {
    main:     'main/',
    word:     'word/',
    letter:   '',
    date:     'calendar/',
    calendar: 'calendar/main/',
    month:    'calendar/',
  },
  humandesign: {
    main:     'main/',
    types:    'types/',
    type:     'types/',
    profiles: 'profiles/',
    profile:  'profiles/',
    result:   'result/',
    places:   'places/',
  },
  humandesignBlocks: {
    types:    'types/',
    profiles: 'profiles/',
    lines:    'lines/',
  },
  header: {
    hair: 'hair/status/today/',
    moon: 'moon/widget/',
  },
  tarotBlocks: {
    today: 'tarot/today/',
  },
  mailing: {
    main: 'subscription/',
  },
};
