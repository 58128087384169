const LOG_LEVEL = 'info';
const DEFAULT_NOTIFICATION_TITLE = 'Рамблер/гороскопы';
const DEFAULT_NOTIFICATION_IMAGE = 'https://horoscopes.rambler.ru/icon-256x256.png';
const SAFARI_WEBSITE_PUSH_ID = 'web.rambler.horoscopes.ru';
const AUTOSUBSCRIBE = true;

enum APPLICATION_CODE {
  release = 'E22B7-44797',
  local = '7E2F6-4E3DC',
  stage = '7E2F6-4E3DC',
  mxx = '7E2F6-4E3DC',
}

const PUSH_DELAY = 7 * 24 * 60 * 60 * 1000; // 7 дней *

// * - при условии того, что пользователь не очистит кеш браузера и не переполнит localStorage

/* eslint-disable */
export default {
  LOG_LEVEL,
  DEFAULT_NOTIFICATION_TITLE,
  DEFAULT_NOTIFICATION_IMAGE,
  SAFARI_WEBSITE_PUSH_ID,
  AUTOSUBSCRIBE,
  APPLICATION_CODE,
  PUSH_DELAY,
};
