export default {
  trait:         'Качество',
  planet:        'Управляющая планета',
  house:         'Управляющий дом',
  tarot:         'Карта Таро',
  color:         'Цвет',
  stone:         'Камень удачи',
  flower:        'Цветы',
  compatibility: 'Лучшая совместимость',
};
