export const FORTUNE_TAG_ID = 130041;

export const SUBSTRATES: {
  circle: (0 | 1 | 2)[]
  side: (0)[]
} = {
  circle: [0, 1, 2],
  side:   [0],
};

export const SYMBOLS_COUNT = 3;

export const LOADING_TIME = 3000;
export const ACTIVATING_TIME = 2000;

export const STEPS: {
  [x: string]: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
} = {
  MAIN:                0,
  LOADING:             1,
  PAST:                2,
  PAST_DESCRIPTION:    3,
  PRESENT:             4,
  PRESENT_DESCRIPTION: 5,
  FUTURE:              6,
  FUTURE_DESCRIPTION:  7,
  RESULT:              8,
};

export const STARS_COUNT = {
  desktop: {
    [STEPS.MAIN]:                { big: 5, small: 5 },
    [STEPS.LOADING]:             { big: 3, small: 3 },
    [STEPS.PAST]:                { big: 3, small: 3 },
    [STEPS.PAST_DESCRIPTION]:    { big: 2, small: 3 },
    [STEPS.PRESENT]:             { big: 2, small: 3 },
    [STEPS.PRESENT_DESCRIPTION]: { big: 2, small: 3 },
    [STEPS.FUTURE]:              { big: 2, small: 3 },
    [STEPS.FUTURE_DESCRIPTION]:  { big: 2, small: 3 },
    [STEPS.RESULT]:              { big: 2, small: 3 },
  },
  mobile: {
    [STEPS.MAIN]:                { big: 3, small: 2 },
    [STEPS.LOADING]:             { big: 3, small: 3 },
    [STEPS.PAST]:                { big: 0, small: 0 },
    [STEPS.PAST_DESCRIPTION]:    { big: 2, small: 3 },
    [STEPS.PRESENT]:             { big: 0, small: 0 },
    [STEPS.PRESENT_DESCRIPTION]: { big: 2, small: 3 },
    [STEPS.FUTURE]:              { big: 0, small: 0 },
    [STEPS.FUTURE_DESCRIPTION]:  { big: 2, small: 3 },
    [STEPS.RESULT]:              { big: 3, small: 5 },
  },
};

export const SYMBOLS_HEIGHTS = {
  desktop: {
    past:    { min: 70, range: 60 },
    present: { min: 70, range: 50 },
    future:  { min: 80, range: 70 },
  },
  mobile: {
    past:    { min: 100, range: 30 },
    present: { min: 60, range: 20 },
    future:  { min: 85, range: 30 },
  },
};

export const SYMBOLS_ROTATION = { min: -20, range: 40 };

export const SYMBOLS_POSITIONS = {
  desktop: {
    past: [
      [
        { top: '150px', right: '150px' },
        { top: '135px', left: '190px' },
        { top: '145px', left: '150px' },
      ],
      [
        { top: '260px', left: '150px' },
        { top: '245px', left: '140px' },
        { top: '270px', left: '160px' },
      ],
      [
        { bottom: '150px', right: '130px' },
        { bottom: '140px', right: '150px' },
        { bottom: '130px', right: '140px' },
      ],
    ],
    present: [
      [
        { top: '155px', left: '195px' },
        { top: '150px', left: '230px' },
        { top: '150px', right: '160px' },
      ],
      [
        { top: '260px', left: '185px' },
        { top: '210px', left: '175px' },
        { top: '225px', left: '190px' },
      ],
      [
        { bottom: '160px', right: '160px' },
        { bottom: '155px', right: '155px' },
        { bottom: '180px', right: '140px' },
      ],
    ],
    future: [
      [
        { top: '185px', right: '80px' },
        { top: '160px', left: '275px' },
        { top: '140px', left: '285px' },
      ],
      [
        { top: '160px', right: '100px' },
        { top: '180px', right: '105px' },
        { top: '170px', right: '110px' },
      ],
      [
        { bottom: '110px', right: '95px' },
        { bottom: '100px', right: '90px' },
        { bottom: '105px', right: '100px' },
      ],
    ],
  },
  mobile: {
    past: [
      [
        { top: '150px', left: 'calc(50% - 5px)' },
        { top: '140px', left: 'calc(50% - 50px)' },
        { top: '145px', left: 'calc(50% - 40px)' },
      ],
      [
        { bottom: '145px', left: 'calc(50% - 100px)' },
        { bottom: '140px', left: 'calc(50% - 110px)' },
        { bottom: '150px', left: 'calc(50% - 115px)' },
      ],
      [
        { bottom: '145px', right: 'calc(50% - 125px)' },
        { bottom: '140px', right: 'calc(50% - 120px)' },
        { bottom: '135px', right: 'calc(50% - 125px)' },
      ],
    ],
    present: [
      [
        { top: '170px', left: 'calc(50% - 25px)' },
        { top: '160px', left: 'calc(50% - 40px)' },
        { top: '165px', left: 'calc(50% - 30px)' },
      ],
      [
        { top: '180px', left: 'calc(50% - 80px)' },
        { top: '190px', left: 'calc(50% - 90px)' },
        { top: '185px', left: 'calc(50% - 85px)' },
      ],
      [
        { bottom: '170px', right: 'calc(50% + 5px)' },
        { bottom: '165px', right: 'calc(50% + 10px)' },
        { bottom: '180px', right: 'calc(50% + 5px)' },
      ],
    ],
    future: [
      [
        { top: '100px', left: 'calc(50% - 25px)' },
        { top: '90px', left: 'calc(50% - 20px)' },
        { top: '95px', left: 'calc(50% - 15px)' },
      ],
      [
        { top: '160px', right: 'calc(50% - 160px)' },
        { top: '150px', right: 'calc(50% - 165px)' },
        { top: '155px', right: 'calc(50% - 170px)' },
      ],
      [
        { bottom: '55px', right: 'calc(50% - 125px)' },
        { bottom: '70px', right: 'calc(50% - 115px)' },
        { bottom: '60px', right: 'calc(50% - 120px)' },
      ],
    ],
  },
};

export const COFFEE_SHARE_TEXT = 'Гадание на кофейной гуще: просто переверните чашку!';

export const SPECIAL_URLS: {
  [x: string]: {
    ts: string
    elem: string
  }
} = {
  'celtic-cross': {
    ts:   '/gadanie-online/taro-keltskiy-krest/',
    elem: 'card',
  },
  'love-fortune': {
    ts:   '/taro/lyubov/',
    elem: 'card',
  },
  'yes-no-fortune': {
    ts:   '/taro/da-net/',
    elem: 'card',
  },
  'future-fortune': {
    ts:   '/taro/budushchee/',
    elem: 'card',
  },
  'birth-date-fortune': {
    ts:   '/taro/data-rozhdeniya/',
    elem: 'card',
  },
  'fortune-rune': {
    ts:   '/gadanie-online/gadanie-na-runah/',
    elem: 'rune',
  },
};
