export enum APP_VERSION {
  MOBILE,
  DESKTOP
}

export enum APP_THEME {
  Sign,
  Stars,
  Brand,
  White,
}

export const THEMES_TYPES: Record<APP_THEME, 'dark' | 'light'> = {
  [APP_THEME.Stars]: 'dark',
  [APP_THEME.Sign]:  'light',
  [APP_THEME.Brand]: 'dark',
  [APP_THEME.White]: 'light',
};
