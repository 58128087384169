export const getCards = (newsLetterCrmKeys: Config['NEWS_LETTER_CRM_KEYS']) => [
  {
    id:     newsLetterCrmKeys.BIORHYTHMS,
    header: {
      icon: 'biorhythms',
      text: 'Биоритмы',
    },
    title: 'Личный прогноз по дате рождения',
    text:  'Уникальный личный прогноз расскажет, как пройдет день, что советуют карты Таро и каким будет ваше самочувствие',
    image: {
      desktop: {
        x1: '/news-letter/preview/desktop/biorhythms.png',
        x2: '/news-letter/preview/desktop/biorhythms@2x.png',
      },
      mobile: {
        x1: '/news-letter/preview/mobile/biorhythms.png',
        x2: '/news-letter/preview/mobile/biorhythms@2x.png',
      },
    },
    ymEvent: 'subscription_popup_biorythm',
  },
  {
    id:     newsLetterCrmKeys.TAROT,
    header: {
      icon: 'tarot',
      text: 'Таро',
    },
    title: 'Еженедельный гид с таро-прогнозом',
    text:  'Пророчества и предсказания для вас каждую неделю. Таро-гид поможет гармонизировать жизнь и лучше понимать себя',
    image: {
      desktop: {
        x1: '/news-letter/preview/desktop/tarot.png',
        x2: '/news-letter/preview/desktop/tarot@2x.png',
      },
      mobile: {
        x1: '/news-letter/preview/mobile/tarot.png',
        x2: '/news-letter/preview/mobile/tarot@2x.png',
      },
    },
    ymEvent: 'subscription_popup_tarot',
  },
  {
    id:     newsLetterCrmKeys.DAILY_SING,
    header: {
      icon: 'horo',
      text: 'Гороскопы',
    },
    title: 'Ежедневный общий гороскоп по знаку зодиака',
    text:  'Начните день с гороскопа, чтобы понять себя, подготовиться к сюрпризам судьбы и не упустить свой шанс на успех',
    image: {
      desktop: {
        x1: '/news-letter/preview/desktop/daily.png',
        x2: '/news-letter/preview/desktop/daily@2x.png',
      },
      mobile: {
        x1: '/news-letter/preview/mobile/daily.png',
        x2: '/news-letter/preview/mobile/daily@2x.png',
      },
    },
    ymEvent: 'subscription_popup_horo',
  },
  {
    id:     newsLetterCrmKeys.ARTICLES,
    header: {
      icon: 'horo',
      text: 'Гороскопы',
    },
    title: 'Вечерний оракул: подборка статей',
    text:  'Подборка лучших астрологических статей за день. Узнайте интересные факты об астрологии и расскажите их друзьям',
    image: {
      desktop: {
        x1: '/news-letter/preview/desktop/articles.png',
        x2: '/news-letter/preview/desktop/articles@2x.png',
      },
      mobile: {
        x1: '/news-letter/preview/mobile/articles.png',
        x2: '/news-letter/preview/mobile/articles@2x.png',
      },
    },
    ymEvent: 'subscription_popup_orakul',
  },
];

export enum SubscribeTypes {
  DAILY_COMMON = 'dailyCommon',
  DAILY_SING = 'dailySign',
  WEEKLY_TAROT = 'weeklyTarot',
  MEDIA_ARTICLES = 'mediaArticles'
}

export const getSubscribePopupTypes = (newsLetterCrmKeys: Config['NEWS_LETTER_CRM_KEYS']) => ({
  [SubscribeTypes.DAILY_COMMON]: {
    title:       'Подпишитесь на ежедневный гороскоп для всех знаков',
    description: 'Начинайте каждый день с предсказания. Гороскоп поможет гармонизировать жизнь и лучше понимать себя',
    icon:        'daily-common',
    id:          newsLetterCrmKeys.DAILY_SING,
  },
  [SubscribeTypes.DAILY_SING]: {
    title:       'Подпишитесь на личный прогноз по дате рождения',
    description: 'Уникальный личный прогноз расскажет, как пройдет день, что советуют карты Таро и каким будет ваше самочувствие',
    icon:        'daily-biorhythms',
    id:          newsLetterCrmKeys.BIORHYTHMS,
  },
  [SubscribeTypes.WEEKLY_TAROT]: {
    title:       'Подпишитесь на еженедельный гид с таро-прогнозом',
    description: 'Пророчества для вас каждую неделю. Будьте готовы к любым сюрпризам судьбы, не упустите свой шанс на успех',
    icon:        'weekly-tarot',
    id:          newsLetterCrmKeys.TAROT,
  },
  [SubscribeTypes.MEDIA_ARTICLES]: {
    title:       'Подпишитесь на вечернюю подборку интересных статей',
    description: 'Подборка лучших астрологических статей за день. Узнайте интересные факты и расскажите их друзьям',
    icon:        'media-articles',
    id:          newsLetterCrmKeys.ARTICLES,
  },
});

export const getWithArgsSing = (newsLetterCrmKeys: Config['NEWS_LETTER_CRM_KEYS']) => [newsLetterCrmKeys.DAILY_SING];
export const getWithArgsBirthdate = (newsLetterCrmKeys: Config['NEWS_LETTER_CRM_KEYS']) => [
  newsLetterCrmKeys.BIORHYTHMS,
  newsLetterCrmKeys.TAROT,
];
export const PROJECT_KEY = 'r_horoscopes';
