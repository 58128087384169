export const METRICS_ENDPOINT = '/ts-metrics/';
export const METRICS_DURATION_QUEUE = 10000;
export const METRICS_AD_COUNT = 'horoscopes_ad_counter';

export const METRICS_NAMES_LIST = [
  'CLS',
  'FCP',
  'FID',
  'LCP',
  'TTFB',
  'TBT',
  METRICS_AD_COUNT,
];

export type MetricsNamesType = {
  name: 'CLS'
  | 'FCP'
  | 'FID'
  | 'LCP'
  | 'TTFB'
  | 'TBT'
  | 'horoscopes_ad_counter'
  value: Record<string, string | number>,
};
