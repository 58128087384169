import type { ZodiacSignsType, ChineseSignsType } from 'config/constants/routes';

export default {
  zodiac: {
    all:         'Общий',
    aries:       'Овен',
    taurus:      'Телец',
    gemini:      'Близнецы',
    cancer:      'Рак',
    leo:         'Лев',
    virgo:       'Дева',
    libra:       'Весы',
    scorpio:     'Скорпион',
    sagittarius: 'Стрелец',
    capricorn:   'Козерог',
    aquarius:    'Водолей',
    pisces:      'Рыбы',
  },
  zodiacDecl: {
    all:         'Общим',
    aries:       'Овнам',
    taurus:      'Тельцам',
    gemini:      'Близнецам',
    cancer:      'Ракам',
    leo:         'Львам',
    virgo:       'Девам',
    libra:       'Весам',
    scorpio:     'Скорпионам',
    sagittarius: 'Стрельцам',
    capricorn:   'Козерогам',
    aquarius:    'Водолеям',
    pisces:      'Рыбам',
  },
  zodialGenitive: {
    all:         'Общих',
    aries:       'Овна',
    taurus:      'Тельца',
    gemini:      'Близнеца',
    cancer:      'Рака',
    leo:         'Льва',
    virgo:       'Девы',
    libra:       'Весов',
    scorpio:     'Скорпиона',
    sagittarius: 'Стрельца',
    capricorn:   'Козерога',
    aquarius:    'Водолея',
    pisces:      'Рыбы',
  },
  zodiacDative: {
    all:         'Общему',
    aries:       'Овну',
    taurus:      'Тельцу',
    gemini:      'Близнецам',
    cancer:      'Раку',
    leo:         'Льву',
    virgo:       'Деве',
    libra:       'Весам',
    scorpio:     'Скорпиону',
    sagittarius: 'Стрельцу',
    capricorn:   'Козерогу',
    aquarius:    'Водолею',
    pisces:      'Рыбам',
  },
  zodiacAccusative: {
    all:         'Общих',
    aries:       'Овнов',
    taurus:      'Тельцов',
    gemini:      'Близнецов',
    cancer:      'Раков',
    leo:         'Львов',
    virgo:       'Дев',
    libra:       'Весов',
    scorpio:     'Скорпионов',
    sagittarius: 'Стрельцов',
    capricorn:   'Козерогов',
    aquarius:    'Водолеев',
    pisces:      'Рыб',
  },
  zodiacTranslit: {
    aries:       'oven',
    taurus:      'telec',
    gemini:      'bliznecy',
    cancer:      'rak',
    leo:         'lev',
    virgo:       'deva',
    libra:       'vesy',
    scorpio:     'skorpion',
    sagittarius: 'strelec',
    capricorn:   'kozerog',
    aquarius:    'vodolej',
    pisces:      'ryby',
  },
  zodiacLatin: {
    oven:     'aries',
    telec:    'taurus',
    bliznecy: 'gemini',
    rak:      'cancer',
    lev:      'leo',
    deva:     'virgo',
    vesy:     'libra',
    skorpion: 'scorpio',
    strelec:  'sagittarius',
    kozerog:  'capricorn',
    vodolej:  'aquarius',
    ryby:     'pisces',
  } as {[x: string]: ZodiacSignsType},
  chinese: {
    all:     'Общий',
    rat:     'Крыса',
    ox:      'Бык',
    tiger:   'Тигр',
    rabbit:  'Кролик',
    dragon:  'Дракон',
    snake:   'Змея',
    horse:   'Лошадь',
    sheep:   'Овца',
    monkey:  'Обезьяна',
    rooster: 'Петух',
    dog:     'Собака',
    pig:     'Свинья',
  },
  chineseTranslit: {
    rat:     'krysa',
    ox:      'byk',
    tiger:   'tigr',
    rabbit:  'krolik',
    dragon:  'drakon',
    snake:   'zmeya',
    horse:   'loshad',
    sheep:   'ovca',
    monkey:  'obezyana',
    rooster: 'petuh',
    dog:     'sobaka',
    pig:     'svinya',
  },
  chineseLatin: {
    krysa:    'rat',
    byk:      'ox',
    tigr:     'tiger',
    krolik:   'rabbit',
    drakon:   'dragon',
    zmeya:    'snake',
    loshad:   'horse',
    ovca:     'sheep',
    obezyana: 'monkey',
    petuh:    'rooster',
    sobaka:   'dog',
    svinya:   'pig',
  } as {[x: string]: ChineseSignsType},
  chineseGenitive: {
    all:     'Общих',
    rat:     'Крысы',
    ox:      'Быка',
    tiger:   'Тигра',
    rabbit:  'Кролика',
    dragon:  'Дракона',
    snake:   'Змеи',
    horse:   'Лошади',
    sheep:   'Овцы',
    monkey:  'Обезьяны',
    rooster: 'Петуха',
    dog:     'Собаки',
    pig:     'Свиньи',
  },
};
