export const PORT = process.env.APP_PORT || 3000;

export const CACHE = false;

export const API_CACHE_TIME = 60;
export const API_URL = 'https://stage.horos.rambler.ru/api/front/';
export const API_TIMEOUT = process.env.API_TIMEOUT_SERVER || 2000;
export const API_TIMEOUT_CLIENT = 10000;

export const API_SUBSCRIPTION = 'https://subscriptions-stage.rambler.ru';

export const API_GEO_LOCATION = 'https://www.rambler.ru/location/current';

export const REDIS_API_CACHE_TIMEOUT = 100;

export const { CAPTCHA_KEY } = process.env;

export const METRICS = true;

const logObj = {
  port:      514,
  type:      'BSD',
  facility:  'local0',
  localhost: process.env.LOG_INDEX,
};
export const SYSLOG = {
  address: process.env.LOG_NODE,
  ...logObj,
};
export const LOGLEVEL = '';
export const LOGGING = true;

export const SENTRY_DSN = 'https://4f714e0240310a72fac5a34261baf9c1@sentry-saas.rambler-co.ru/95';
export const SENTRY_SAMPLE_RATE_SERVER = 1;
export const SENTRY_SAMPLE_RATE_BROWSER = 1;

export const METRICS_SAMPLE_RATE = 1;

export const HTTPS_PROXY = process.env.PROXY_URL || '';
export const HD_REJECT_UNAUTHORIZED = !(process.env.HD_REJECT_UNAUTHORIZED && process.env.HD_REJECT_UNAUTHORIZED === 'false');

export const ANTIADBLOCK = {
  SCRIPT:   'https://stage.horos.rambler.ru/aab/Anti-AdBlock-Stage.js',
  CHECKSUM: 'https://stage.horos.rambler.ru/aab/Anti-AdBlock-Stage.md5',
};

export const SPLITS_JSON = `${API_URL}v3/experiments/`;
