export const CALENDAR_LEGENDS: {
  [key: string]: {
    label: string
    statuses: {
      [status: string]: string
    }
  }
} = {
  names: {
    label:    '',
    statuses: {
      count: 'Количество именин на день',
    },
  },
  haircut: {
    label:    'для стрижки',
    statuses: {
      lucky:   'Удачно',
      unlucky: 'Неудачно',
      neutral: 'Нейтрально',
    },
  },
  manicure: {
    label:    'для маникюра',
    statuses: {
      lucky:   'Удачно',
      unlucky: 'Неудачно',
      neutral: 'Нейтрально',
    },
  },
  epilation: {
    label:    'для эпиляции',
    statuses: {
      lucky:   'Удачно',
      unlucky: 'Неудачно',
      neutral: 'Нейтрально',
    },
  },
  bodycare: {
    label:    'для ухода',
    statuses: {
      lucky:   'Удачно',
      unlucky: 'Неудачно',
      neutral: 'Нейтрально',
    },
  },
};

export const MONTHS_ENG = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const MONTHS = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь',
];

export const MONTHS_ABBR = [
  'янв',
  'фев',
  'мар',
  'апр',
  'май',
  'июн',
  'июл',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек',
];

export const MONTHS_GEN = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];

export const MONTHS_PREP = [
  'январе',
  'феврале',
  'марте',
  'апреле',
  'мае',
  'июне',
  'июле',
  'августе',
  'сентябре',
  'октябре',
  'ноябре',
  'декабре',
];

export const MONTHS_ABBR_GEN = [
  'янв',
  'фев',
  'марта',
  'апр',
  'мая',
  'июня',
  'июля',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек',
];

export const WEEKDAYS_ABBR = [
  'пн',
  'вт',
  'ср',
  'чт',
  'пт',
  'сб',
  'вс',
];

export const WEEKDAYS = [
  'Воскресенье',
  'Понедельник',
  'Вторник',
  'Среда',
  'Четверг',
  'Пятница',
  'Суббота',
];

export const WEEKDAYS_ALIAS = {
  SUNDAY:    0,
  MONDAY:    1,
  TUESDAY:   2,
  WEDNESDAY: 3,
  THURSDAY:  4,
  FRIDAY:    5,
  SATURDAY:  6,
};

export const DAYS_IN_WEEK = 7;
export const MIN_YEAR = 1910;
export const MAX_YEAR = 2070;
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_DISPLAY_FORMAT = 'dd.MM.yyyy';
export const TIME_DISPLAY_FORMAT = 'HH:mm';
export const CALENDAR_INIT_DATE = '2012-12-21';

export const CALENDAR_TABS: {
  [key: string]: {
    [tab: string]: string
  }
} = {
  hair: {
    haircut:   'Стрижка',
    manicure:  'Маникюр',
    epilation: 'Эпиляция',
    bodycare:  'Уход',
  },
};

export const CALENDAR_DAYS: {
  [key: string]: string
} = {
  lucky:   'Благоприятные дни',
  unlucky: 'Неблагоприятные дни',
  neutral: 'Нейтральные дни',
};
