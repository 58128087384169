export enum GENDER {
  male = 'male',
  female = 'female',
  unknown = 'unknown',
}

export enum GENDER_CLASSIC {
  woman = 'woman',
  man = 'man',
}
